<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="CastIcon" size="19"/>
          <h4 class="mb-0 ml-50">Duyuru Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="9" lg="9">
            <b-row>

              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Başlık" rules="required">
                  <b-form-group label="* Başlık" :state="getValidationState(validationContext)">
                    <b-form-input trim placeholder="Başlık" v-model="dataInfo.title"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Kategori" rules="required">
                  <b-form-group label="* Kategori" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.announcementCategoryId" :options="categoryOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Özel Duyuru">
                  <b-form-group label="Özel Duyuru" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isPrivate" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Başlangıç Zamanı" rules="required">
                  <b-form-group label="* Başlangıç Zamanı" :state="getValidationState(validationContext)">
                    <flatpickr v-model="dataInfo.startDate" class="form-control" :config="{enableTime: true, defaultHour: 0, defaultMinute: 0, minuteIncrement: 1, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Bitiş Zamanı" rules="required">
                  <b-form-group label="* Bitiş Zamanı" :state="getValidationState(validationContext)">
                    <flatpickr v-model="dataInfo.endDate" class="form-control" :config="{enableTime: true, defaultHour: 23, defaultMinute: 59, minuteIncrement: 1, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Öncelik" rules="required">
                  <b-form-group label="Öncelik">
                    <b-form-input trim placeholder="Öncelik" v-model="dataInfo.priorityNumber" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Durum" rules="required">
                  <b-form-group label="Durum" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12" v-if="!$router.currentRoute.params.id">
                <validation-provider #default="validationContext" name="Döküman Ekle">
                  <b-form-group label="Döküman Ekle">
                    <b-form-file id="announcementFiles" name="announcementFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Duyuru İçeriği" rules="required">
                  <b-form-group label="Duyuru İçeriği">
                    <b-form-textarea v-model="dataInfo.description" rows="6" placeholder="Duyuru İçeriği..."/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>
          </b-col>

          <b-col cols="12" md="3" lg="3" class="d-flex flex-wrap justify-content-center align-content-start">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-avatar
                    rounded button
                    size="200px"
                    ref="previewEl"
                    :src="(dataInfo.imageBase64 !== null ? dataInfo.imageBase64 : getApiFile(dataInfo.imagePath))"
                    variant="primary"
                    @click="$refs.refInputEl.click()">
                  <feather-icon icon="ImageIcon" size="150" v-if="dataInfo.imageBase64 === null && dataInfo.imagePath === null"/>
                </b-avatar>
                <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
              </b-col>
            </b-row>
          </b-col>

        </b-row>

        <div class="d-flex justify-content-between align-items-center border-bottom border-top pt-1 pr-1 pb-1" v-if="dataInfo.isPrivate">
          <div class="d-flex">
            <feather-icon icon="CastIcon" size="19"/>
            <h4 class="mb-0 mt-0 ml-50">Hedef Bilgileri</h4>
          </div>
          <b-button variant="primary" @click="newTargetModal" size="md">
            <feather-icon icon="PlusSquareIcon" size="16"/>
            <span class="align-middle" role="button"> Ekle</span>
          </b-button>
        </div>

        <div v-for="(target, index) in dataInfo.targets" :key="index" class="d-flex justify-content-between align-items-center border-bottom py-1" v-if="dataInfo.isPrivate && !target.isDeleted">
          <div class="d-flex justify-content-between align-items-center">
            <b-avatar size="32" class="mr-50" :text="(index+1).toString()" :variant="`light-primary`"/>
            <div class="user-page-info">
              <h6 class="mb-0">
                {{ target.announcementTargetTypeName }}
              </h6>
              <small class="text-muted">{{ target.targetName }}</small>
            </div>
          </div>
          <div>
            <b-button variant="warning" class="btn-icon" @click="editTarget(target)">
              <feather-icon icon="EditIcon"/>
              Düzenle
            </b-button>
            <b-button variant="danger" class="btn-icon ml-1" @click="removeTarget(target)">
              <feather-icon icon="XIcon"/>
              Çıkar
            </b-button>
          </div>
        </div>

        <b-modal v-model="modalShow" title="Hedef Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" no-close-on-esc no-close-on-backdrop @ok="handleModal">
          <form ref="form" @submit.stop.prevent="submitModal">

            <b-form-group label="Duyuru Hedef Tipi">
              <v-select v-model="targetInfo.announcementTargetTypeId" :options="targetTypeOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="İşyeri" v-if="targetInfo.announcementTargetTypeId === 'WORKPLACE'">
              <v-select v-model="targetInfo.targetId" :options="workplaceOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Organizasyon" v-if="targetInfo.announcementTargetTypeId === 'ORGANIZATION'">
              <v-select v-model="targetInfo.targetId" :options="organizationOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Pozisyon" v-if="targetInfo.announcementTargetTypeId === 'POSITION'">
              <v-select v-model="targetInfo.targetId" :options="positionOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Kullanıcı" v-if="targetInfo.announcementTargetTypeId === 'USER'">
              <v-select v-model="targetInfo.targetId" :options="userOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

          </form>
        </b-modal>

        <action-buttons :back-route="'announcement-management'"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {BAvatar, BBadge, BButton, BButtonGroup, BButtonToolbar, BCard, BCardText, BCol, BDropdown, BDropdownItem, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BFormTimepicker, BListGroup, BListGroupItem, BMedia, BMediaAside, BMediaBody, BModal, BRow, BTable, BTooltip} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/announcement/announcement/store"
import categoryModule from "@/views/announcement/category/store"
import definitionModule from "@/views/system/definition/store"
import workplaceModule from "@/views/organization/workplace/store"
import organizationModule from "@/views/organization/organization/store"
import positionModule from "@/views/organization/position/store"
import userModule from "@/views/organization/employee/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import flatpickr from "vue-flatpickr-component/src/component"
import {BooleanColumnTable, DateColumnTable, IdColumnTable, RemoveButtonTable} from "@/components/Table";
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";
import Vue from "vue";
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BooleanColumnTable,
    DateColumnTable, IdColumnTable, DateTimeColumnTable, RemoveButtonTable,
    BTooltip, BDropdownItem, BButtonGroup, BDropdown, BButtonToolbar,
    BFormTimepicker,
    BBadge,
    BFormFile,
    BCardText,
    BMediaBody,
    BMediaAside,
    BMedia,
    BListGroupItem,
    BListGroup,
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_CATEGORY_MODULE_NAME = 'store-category'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_WORKPLACE_MODULE_NAME = 'store-workplace'
    const STORE_ORGANIZATION_MODULE_NAME = 'store-organization'
    const STORE_POSITION_MODULE_NAME = 'store-position'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_CATEGORY_MODULE_NAME, categoryModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_WORKPLACE_MODULE_NAME, workplaceModule)
      store.registerModule(STORE_ORGANIZATION_MODULE_NAME, organizationModule)
      store.registerModule(STORE_POSITION_MODULE_NAME, positionModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_CATEGORY_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_WORKPLACE_MODULE_NAME)
        store.unregisterModule(STORE_ORGANIZATION_MODULE_NAME)
        store.unregisterModule(STORE_POSITION_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: null,
      announcementCategoryId: null,
      title: '',
      description: '',
      startDate: null,
      endDate: null,
      priorityNumber: 1,
      imageBase64: null,
      imagePath: null,
      isPrivate: false,
      isActive: true,
      targets: [],
    })
    const targetInfo = ref({
      id: 0,
      announcementTargetTypeId: null,
      targetId: null,
      announcementTargetTypeName: null,
      targetName: '',
      index: null,
      isDeleted: false
    })

    const targetTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ANNOUNCEMENT_TARGET_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        targetTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.announcementTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const categoryOptions = ref([])
    busy.value = true
    store.dispatch('store-category/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          categoryOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const workplaceOptions = ref([])
    busy.value = true
    store.dispatch('store-workplace/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          workplaceOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const organizationOptions = ref([])
    busy.value = true
    store.dispatch('store-organization/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          organizationOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const positionOptions = ref([])
    busy.value = true
    store.dispatch('store-position/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          positionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {sortColumn: 'firstName', sortDesc: false, isActive: true}).then(response => {
      response.data.data.forEach((value, index) => {
        if (!userOptions.value.some(e => e.value === value.id)) {
          userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture, positionName: value.positionName, firstName: value.firstName, lastName: value.lastName})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {

      if (dataInfo.value.isPrivate && dataInfo.value.targets.filter(item => item.isDeleted === false).length === 0) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Hedef bilgileri boş geçilemez!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        if (!router.currentRoute.params.id && document.getElementById('announcementFiles').files.length > 0) {
          const documents = document.getElementById('announcementFiles').files
          const formData = new FormData();

          for (let i = 0; i < documents.length; i++) {
            let file = documents[i];
            formData.append('documents[' + i + ']', file);
          }

          store.dispatch('store/addDocument', {announcementId: response.data.data, dataInfo: formData}).then(response => {
            toastMessage(toast, 'success', response.data.message)
            router.push({name: 'announcement-management'})
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
          }).finally(message => {
            busy.value = false
          })

        } else {
          toastMessage(toast, 'success', response.data.message)
          router.push({name: 'announcement-management'})
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const targetEditInfo = ref(null)
    const modalShow = ref(false)

    const newTargetModal = () => {
      targetInfo.value = {
        id: 0,
        announcementTargetTypeId: null,
        targetId: null,
        announcementTargetTypeName: null,
        targetName: '',
        index: null,
        isDeleted: false
      }

      modalShow.value = true
    }

    const submitModal = () => {
      let isAppendItem = true

      let announcementTargetTypeName = ''
      let targetName = '';

      if (targetInfo.value.announcementTargetTypeId === 'WORKPLACE') {
        announcementTargetTypeName = 'İşyeri'
        targetName = workplaceOptions.value.filter(item => item.value === targetInfo.value.targetId)[0].label
      } else if (targetInfo.value.announcementTargetTypeId === 'ORGANIZATION') {
        announcementTargetTypeName = 'Organizasyon'
        targetName = organizationOptions.value.filter(item => item.value === targetInfo.value.targetId)[0].label
      } else if (targetInfo.value.announcementTargetTypeId === 'POSITION') {
        announcementTargetTypeName = 'Pozisyon'
        targetName = positionOptions.value.filter(item => item.value === targetInfo.value.targetId)[0].label
      } else if (targetInfo.value.announcementTargetTypeId === 'USER') {
        announcementTargetTypeName = 'Kullanıcı'
        targetName = userOptions.value.filter(item => item.value === targetInfo.value.targetId)[0].label
      }

      dataInfo.value.targets.forEach((value, index) => {
        if (value === targetEditInfo.value) {
          isAppendItem = false
          value.id = targetInfo.value.id
          value.announcementTargetTypeId = targetInfo.value.announcementTargetTypeId
          value.targetId = targetInfo.value.targetId
          value.announcementTargetTypeName = announcementTargetTypeName
          value.targetName = targetName
          value.isDeleted = targetInfo.value.isDeleted
          value.index = Math.floor(Math.random() * 112114115171)
        }
      });

      if (isAppendItem) {
        dataInfo.value.targets.push({
          id: targetInfo.value.id,
          announcementTargetTypeId: targetInfo.value.announcementTargetTypeId,
          targetId: targetInfo.value.targetId,
          announcementTargetTypeName: announcementTargetTypeName,
          targetName: targetName,
          isDeleted: targetInfo.value.isDeleted,
          index: Math.floor(Math.random() * 112114115171)
        })
      }

      targetInfo.value = {
        id: 0,
        announcementTargetTypeId: null,
        targetId: null,
        announcementTargetTypeName: null,
        targetName: '',
        index: null,
        isDeleted: false
      }

      targetEditInfo.value = null

      modalShow.value = false
    }

    const handleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (targetInfo.value.announcementTargetTypeId === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Hedef tipi seçimi zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (targetInfo.value.targetId === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Hedef seçimi zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitModal()
    }

    const removeTarget = (targetData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.targets.forEach((value, index) => {
            if (value === targetData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const editTarget = (targetData) => {
      targetEditInfo.value = targetData
      targetInfo.value = {
        id: targetData.id,
        announcementTargetTypeId: targetData.announcementTargetTypeId,
        targetId: targetData.targetId,
        announcementTargetTypeName: targetData.announcementTargetTypeName,
        targetName: targetData.targetName,
        isDeleted: targetData.isDeleted,
      };

      modalShow.value = true
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'announcement-management'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.imageBase64 = base64
    })

    return {
      busy,
      dataInfo,
      targetInfo,
      refFormObserver,
      categoryOptions,
      targetTypeOptions,
      workplaceOptions,
      organizationOptions,
      positionOptions,
      userOptions,
      modalShow,
      previewEl,
      refInputEl,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      newTargetModal,
      submitModal,
      handleModal,
      removeTarget,
      editTarget,
      avatarText,
      getApiFile,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker';

.select-size-sm {
  min-width: 100%;
}

.empty-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.scroll-flow {
  max-height: 650px;
  overflow-y: auto;
}
</style>
